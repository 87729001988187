/* tslint:disable */
/* eslint-disable */
/**
 * Rawe Ceek API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionDto
 */
export interface SessionDto {
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    startTime: string;
}

/**
 * Check if a given object implements the SessionDto interface.
 */
export function instanceOfSessionDto(value: object): value is SessionDto {
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('startTime' in value) || value['startTime'] === undefined) return false;
    return true;
}

export function SessionDtoFromJSON(json: any): SessionDto {
    return SessionDtoFromJSONTyped(json, false);
}

export function SessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'summary': json['summary'],
        'location': json['location'],
        'startTime': json['startTime'],
    };
}

  export function SessionDtoToJSON(json: any): SessionDto {
      return SessionDtoToJSONTyped(json, false);
  }

  export function SessionDtoToJSONTyped(value?: SessionDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'summary': value['summary'],
        'location': value['location'],
        'startTime': value['startTime'],
    };
}

